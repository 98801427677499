import React, {useState} from 'react';
import {Chip, Table, TableBody, TableHead, TableRow, Avatar, Tooltip, withStyles} from "@material-ui/core";
import {CustomTableCell} from "../components/evs/EVsComponent";
import PaginationComponent from "./PaginationComponent";
import {styles} from "../styles/CommonStyles";
import moment from "moment";
import {getMonth} from "../components/moteur/PayrollEngineComponent";
import CreatedIcon from "../assets/images/8989.png";
import ValidatedIcon from "../assets/images/1212.png";
import {strings} from "../i18n/Strings";
import AdjustIcon from '@material-ui/icons/Adjust';
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline';

const TableComponent = ({classes, columns, list, periods, color}) => {

    const [page, setPage] = useState(0);
    const {_noDataFound} = strings;

    const getLabel = data => {
        switch (data) {
            case 'HMO M-1' :
                return {
                    code: 'H',
                    value: 'HMO M-1',
                    color: '#C1436D'
                };
            case 'Le temps contractuel' :
                return {
                    code: 'TC',
                    value: 'Le temps contractuel',
                    color: '#1a909e'
                };
            case 'La liste des non signataire' :
                return {
                    code: 'NS',
                    value: 'La liste des non signataire',
                    color: '#1181B2'
                };
            case 'Mutations' :
                return {
                    code: 'MT',
                    value: 'Mutations',
                    color: '#B65741'
                };
            case 'Correction Planché' :
                return {
                    code: 'CP',
                    value: 'Correction Planché',
                    color: '#c54b8c'
                };
            case 'Dates hotliner' :
                return {
                    code: 'DH',
                    value: 'Dates hotliner',
                    color: '#217a57'
                };
            case 'MODULATION A-1' :
                return {
                    code: 'HA1',
                    value: 'MODULATION A-1',
                    color: '#210c13'
                };
            default :
                return null
        }
    }
    const getChips = (elements) => {
        let rows = [];

        for (const i in elements) {
            const {code, value, color} = getLabel(elements[i]);
            rows.push(
                <Tooltip title={value}>
                    <Avatar style={{backgroundColor: color, width: 37, height: 37}}>{code}</Avatar>
                </Tooltip>
            )
        }

        return rows
    }


    const getColumns = (elements, color) => {
        let rows = [];

        columns.map(column => {
            if (column.code === 'period') {
                rows.push(<CustomTableCell align="center" component="th"
                                           scope="row">{getMonth(moment(elements[column.code]).format('M'), periods)}</CustomTableCell>)
            } else if (column.code && column.code.includes('date')) {
                rows.push(<CustomTableCell align="center" component="th" scope="row">
                    {elements[column.code] ? elements[column.code].replace('T', '  ') : '--'}
                </CustomTableCell>)
            } else if (column.code === 'statut') {
                rows.push(<CustomTableCell align="center">
                    <Tooltip style={{cursor: 'pointer'}}
                             title={elements[column.code] !== 'Termine' ? 'EN COURS' : 'TERMINÉ'}>
                        {elements[column.code] !== 'Termine' ?
                            <AdjustIcon style={{cursor: 'pointer', color: color}}/> : <CheckCircleOutlineIcon
                                style={{cursor: 'pointer', color: color}}/>}
                    </Tooltip>
                </CustomTableCell>)
            }
                // else if (column.code === 'sheetsUploadedDTO') {
                //     elements[column.code] && elements[column.code].length === 0 ?
                //         rows.push(<CustomTableCell align="center">-</CustomTableCell>) :
                //         rows.push(<CustomTableCell
                //             align="center">{getChips(elements[column.code], color)}</CustomTableCell>);
            // }
            else {
                rows.push(<CustomTableCell align="center" component="th"
                                           scope="row">{elements[column.code] && elements[column.code].toUpperCase()}</CustomTableCell>)
            }
        });

        return rows;
    }

    const handleChangePage = async (event, newPage) => {
        setPage(newPage - 1);
    };

    return (
        <div style={{height: '90vh', overflow: 'scroll', paddingTop: 25}}>
            <Table className={classes.table}>
                <TableHead style={{background: color}}>
                    <TableRow>
                        {columns && columns.map(column =>
                            <CustomTableCell align="center">{column.value}</CustomTableCell>)}
                    </TableRow>
                </TableHead>
                <TableBody>
                    {list && list.map(row => (
                        <TableRow className={classes.row} key={row.id}>
                            {getColumns(row, color)}
                        </TableRow>
                    ))}
                </TableBody>
            </Table>

            <PaginationComponent color={color} rows={list} page={page} classes={classes}
                                 handleChangePage={handleChangePage}/>

            {list && list.length === 0 && (
                <div className={classes.root}>
                    <p style={{
                        paddingBottom: '0px', color: color, textAlign: 'center', fontSize: '25px',
                        fontFamily: 'Roboto'
                    }}>{_noDataFound}
                    </p>
                </div>
            )}
        </div>
    );
};

export default withStyles(styles)(TableComponent);
